module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0dfc2e4c4a544842ac1f28801e2cfcae@sentry.io/1215120","tracesSampleRate":1,"ignoreUrls":["https://botwise.us20.list-manage.com/subscribe/post?u=c9a3d849206a1f7becfcd6b2c&id=91448d85d5","https://botwise.us7.list-manage.com/subscribe/post?u=89a9d771ae94e258051da298b&amp;id=ed2c6ea1a8"],"ignoreErrors":["Network request failed","Failed to fetch","NetworkError","Network Error"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BOTWISE | Szybki dostęp do rozproszonej wiedzy w organizacji","short_name":"Botwise","start_url":"/","background_color":"#ffffff","theme_color":"#20bca2","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bed14e6189550692203ab62a633e4109"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NJLKKHV","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-175431768-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
