exports.components = {
  "component---src-containers-blog-templates-index-index-js": () => import("./../../../src/containers/Blog/templates/Index/index.js" /* webpackChunkName: "component---src-containers-blog-templates-index-index-js" */),
  "component---src-containers-blog-templates-post-post-js": () => import("./../../../src/containers/Blog/templates/Post/Post.js" /* webpackChunkName: "component---src-containers-blog-templates-post-post-js" */),
  "component---src-containers-blog-templates-tag-tag-js": () => import("./../../../src/containers/Blog/templates/Tag/Tag.js" /* webpackChunkName: "component---src-containers-blog-templates-tag-tag-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cc-report-2020-js": () => import("./../../../src/pages/ccReport2020.js" /* webpackChunkName: "component---src-pages-cc-report-2020-js" */),
  "component---src-pages-chat-gpt-js": () => import("./../../../src/pages/chatGPT.js" /* webpackChunkName: "component---src-pages-chat-gpt-js" */),
  "component---src-pages-customer-care-lp-js": () => import("./../../../src/pages/customerCareLP.js" /* webpackChunkName: "component---src-pages-customer-care-lp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/termsConditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-ue-funds-js": () => import("./../../../src/pages/ueFunds.js" /* webpackChunkName: "component---src-pages-ue-funds-js" */)
}

